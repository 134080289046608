import md5 from 'js-md5'
export var SearchList = [
  { labe: '送货单号', code: 'livraisonCode', type: 'input' },
  // {
  //   labe: '送货基地',
  //   code: 'baseName',
  //   type: 'select',
  //   option: []
  // },
  { labe: '供应商', code: 'provider', type: 'input', placeholder: '编码/名称' },
  { labe: '物料编号', code: 'materialCode', type: 'input' },
  { labe: '需求单号', code: 'demandCode', type: 'input' },
  { labe: '采购订单编号', code: 'orderCode', type: 'input' },
  { labe: '内向送货单号', code: 'reverseCode', type: 'input' }
  // {
  //   labe: '送货单状态',
  //   code: 'status',
  //   type: 'select',
  //   option: [
  //     { label: '已发布', value: '0' },
  //     { label: '已发货', value: '1' },
  //     { label: '已作废', value: '2' }
  //   ]
  // },
]

export var SearchData = {
  livraisonCode: '',
  baseName: '',
  provider: '',
  demandCode: '',
  orderCode: '',
  reverseCode: ''
}

export var tableField = [
  { label: '送货单号', code: 'livraisonCode', type: 'input', width: '150' },
  { label: '送货需求单号', code: 'demandCode', type: 'input', width: '150' },
  { label: '送货需求行号', code: 'demandRow', type: 'input', width: '110' },
  { label: '供应商名称', code: 'providerName', type: 'input', width: '140' },
  { label: '物料编号', code: 'materialCode', type: 'input', width: '140' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '130' },
  { label: '需求数量', code: 'demandNumber', type: 'input', width: '' },
  { label: '已发货数量', code: 'livraisonNumber', type: 'input', width: '110' },
  { label: '内向送货单号', code: 'reverseCode', type: 'input', width: '110' },
  { label: '下发日期', code: 'issueTime', type: 'input', width: '140' },
  { label: '预计送达日期', code: 'expectTime', type: 'input', width: '110' },
  { label: '期望送达日期', code: 'demandTime', type: 'input', width: '110' },
  { label: '采购单编号', code: 'orderCode', type: 'input', width: '130' },
  { label: '采购单行号', code: 'orderRow', type: 'input', width: '100' },
  { label: '待响应数量', code: 'remainNumber', type: 'input', width: '100' },
  { label: '需求送货基地', code: 'baseName', type: 'input', width: '110' },
  // { label: '基地地址', code: '', type: 'input', width: '110' },
  {
    label: '状态',
    code: 'status',
    type: 'function',
    width: '60px',
    handle: (index, data) => {
      const color = md5(data.status + '').substring(10, 16)
      const label = data.status === 0 ? '已发布' : data.status === 1 ? '已发货' : data.status === 2 ? '已作废' : ''
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '需求类型',
    code: 'demandType',
    type: 'function',
    width: '80',
    handle: (index, data) => {
      return data.demandType === 1 ? '集采分发' : data.demandType === 2 ? '兜底入库'
        : data.demandType === 3 ? '战略入库' : data.demandType === 4 ? '备货入库'
          : data.demandType === 5 ? '分发换料' : data.demandType === 6 ? '辅料入库' : ''
    }
  }
]

export var tableData = []
export var menudata = [{
  label: '导出',
  action: 'Export',
  id: 1
}
]
