<template>
<!-- 送货需求执行报表 -->
  <div class="list">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
      <div class="contractMenu">
        <list-menu @buttonClick="buttonClick" :menuData="menudata" ></list-menu>
      </div>
    <global-table :tableField="tableField" :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"
    ></global-table>
  </div>
</template>

<script>
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/setting.js'
import { request, exportForm } from '@/assets/js/http.js'
import { MIME } from '@/assets/js/mime.js'
import qs from 'qs'
export default {
  components: Component.components,
  name: 'DeliveryReport',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      searchdata: SearchData,
      pageNum: 1,
      paginationData: {
        pageSize: pageSize,
        total: 0
      },
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.myBidData()
  },
  mounted () {
    // 获取送货基地列表
    var newOption = []
    request('/api/livraison/base/getBaseInfoByName?' + 'baseName=' + '', 'get').then((res) => {
      res.data.forEach(item => {
        newOption.push({
          label: item.baseName,
          value: item.baseCode
        })
      })
      this.childData.searchList[0].option = newOption
    })
  },
  methods: {
    // 获取采购交付-直发送货需求列表数据
    myBidData () {
      const obj = {
        pageNum: this.pageNum,
        pageSize: this.paginationData.pageSize,
        ...this.childData.searchData
      }
      request('/api/livraison/livrasion/list', 'GET', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          if (this.paginationData.total !== res.data.total) {
            this.paginationData.total = res.data.total
          }
        }
      })
    },
    buttonClick () { // 导出
      var file = { name: '送货需求执行报表.xls' }
      exportForm('/api/livraison/livrasion/poiExcel?' + qs.stringify(this.searchdata), 'get').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    },
    // 查询
    onSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] === 'All' || data[key] === '') {
          delete data[key]
        }
      })
      this.pageNum = 1
      this.myBidData()
    },
    // 分页
    pageChange (data) {
      this.pageNum = data
      this.myBidData()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
.contractMenu{
  float: right;
}
</style>
